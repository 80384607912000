import React from "react";
import useSiteMetadata from "../SiteMetadata";

export const Head = ({data}) => {
  const { title, description, keywords } = useSiteMetadata();
  const pageTitle = `${title}${data?.markdownRemark?.frontmatter?.title ? ` - ${data?.markdownRemark?.frontmatter?.title}` : ''}`;
  return (
    <>
      <title>{pageTitle}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords.join(',')} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      <link rel="preconnect" href="https://cdnjs.cloudflare.com" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css"
      />
      <meta name="theme-color" content="#fff" />

      <meta property="og:title" content={title} />
      <meta property="og:url" content="/" />
    </>
  );
}