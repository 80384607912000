import styled from "styled-components";

export const PostItem = styled.div`
  a {
    padding-bottom: 15px;
    border-bottom: 1px solid black;
    font-family: 'Raleway';
    font-style: normal;
    min-height: 45ex;
    color: black;
    display: flex;
    flex-direction: column;
    & > div.image {
      min-width: 247px;
    }
    & > div.text{
      display: flex;
      flex-flow: column nowrap;
    }
    & > div.text h4 {
      margin-bottom: 12px;
    }
    & > div.text p.date{
      margin-top:auto;
    }
    @media (min-width: 1024px){
      flex-direction: row;
      min-height: auto;
      margin-left: 0;
      & > div.image {
        min-width: 337px;
      }
      & > div.text {
        padding-left: 40px; 
      }
      & > div.text h4 {
        margin-bottom: 14px;
      }
    }
  }
`;

export const PostsList = styled.ul`
  grid-template-columns: repeat( auto-fit,minmax(335px,1fr) );
  display: grid;
  justify-items: center;
  margin: 0 -15px;
  padding: 15px 0;
  @media (min-width: 1024px){
    grid-template-columns: repeat( auto-fit,minmax(365px,1fr) );
  }
`;

export const PostCard = styled.li`
  width: 335px;
  background: #FFFFFF;
  border-radius: 25px;
  overflow: hidden;
  padding: 14px;
  display: flex;
  flex-flow: column nowrap;
  min-height: 337px;
  margin-bottom: 40px;
  a {
    color: black;
  }
  div.postcard-bottom{
    margin-top:35px;
    font-weight: 500;
    font-size: 11.3449px;
    line-height: 16px;
    display: flex;
    justify-content: space-between;
  }
  p {
    margin: 0;
  }
  h3 {
    margin: 17px 0 7px;
  }
  button {
    color: black;
    background: white;
    border-color: black;
  }
  @media (min-width: 1024px){
    min-height: 408px;
    width: 368px;
    padding: 16px;
  }
`;

export const CarouselContainer = styled.div`
  flex-grow: 4;
  max-width: 100%;
  @media (min-width: 1024px){
    max-width: 90%;
  }
  & .alice-carousel {
    width: 0;
    min-width: 100%;
  }
`;

export const FeaturedPostsContainer = styled.div`
  display:flex;
  margin: 15px 0;
`;


export const PodcastContainer = styled.div`
  margin: 20px auto;
  @media (min-width: 1024px){
    margin: 40px 0;
  }
`;

export const YoutubeContainer = styled.div`
  margin: 20px auto;
  width: 516px;
  height: 300px;
  @media (min-width: 1024px){
    margin: 40px auto;
  }
`;

export const SinglePostTopContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px){
    flex-direction: row;
  }
  & div.image{
    width: 90%;
    margin: 15px auto;
    display: flex;
    justify-content: center;

    & > div.gatsby-image-wrapper{
      border-radius: 50%;
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      width: 225px;
      height: 225px;
      @media (min-width: 1024px){
        width: 450px;
        height: 450px;
      }
      overflow: hidden;
    }
  }
`;