import React from "react";
import { PostsList, PostCard } from "./styles";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Button } from "../../styles";

export const OtherPosts = ({
  posts
}) => {

  return (
    <PostsList>
      {posts?.map((post) => (
        <PostCard key={post.fields.slug}>
          <Link to={post.fields.slug}>
            <div className="image card-image">
              <GatsbyImage image={getImage(post.frontmatter.image.image) || post.frontmatter.image.image} alt={post.frontmatter.image.alt} title={post.frontmatter.image.alt} />
            </div>
            <h3>
              {post.frontmatter.title}
            </h3>
            <p>
              {post.frontmatter.description}
            </p>
            <div className="postcard-bottom">
              Publié {post.frontmatter.date}
              <Button to={post.fields.slug}>
                Lire l'article
              </Button>
            </div>
          </Link>
        </PostCard>
      ))}
    </PostsList>
  );
}

export default OtherPosts;