import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Core/Layout";
import { HTMLContent } from "../components/Content";
import { BlogPostComponent } from "../components/Pages/Blog/SinglePost";

// eslint-disable-next-line
export const BlogPostTemplate = (props) => (
    <BlogPostComponent {...props} />
);

BlogPostTemplate.propTypes = {
  body: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
};

const BlogPost = ({ data, pageContext }) => {
  const { markdownRemark: post, posts } = data;
  const others = posts.edges.filter((p) => {
    return p.node.id !== pageContext.id;
  }).splice(0,3).map((edge) => edge.node);
  return (
    <Layout>
      <BlogPostTemplate
        body={post.html}
        contentComponent={HTMLContent}
        image={post.frontmatter.image}    
        smallImage={post.frontmatter.smallImage}
        podcastId={post.frontmatter.podcastId}  
        youtubeId={post.frontmatter.youtubeId}
        title={post.frontmatter.title}
        title2={post.frontmatter.title2}
        date={post.frontmatter.date}
        otherPosts={others}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export { Head } from "../components/Core/HtmlHead";

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        title2
        podcastId
        youtubeId
        date(formatString: "[Le] DD MMMM YYYY", locale: "fr-CA")
        image{
          ...imageSinglePost
        }
        smallImage: image {
          ...imageSmallSinglePost
        }
      }
    }
    posts: allMarkdownRemark (
      sort: {order: DESC, fields: [frontmatter___date]}
      filter: {frontmatter: {templateKey: {eq: "blog-post"}, published: {eq: true}}}
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            title2
            podcastId
            date(formatString: "[Le] DD MMMM YYYY", locale: "fr-CA")
            dateUnformatted: date(formatString: "YYYY-MM-DD")
            featured
            image{
              ...imageBlogListBlock
            }
            smallImage: image {
              ...imageSmallBlogListBlock
            }
            description
          }
        }
      }
    }
  }
`;
