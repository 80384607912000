import styled from "styled-components";
import { Link } from "gatsby";

export const ButtonsBar = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (min-width: 1024px) {
    min-width: 60%;
    flex-direction: row;
  }
`;

export const ButtonLink = styled(Link)`
  color: ${props => props.theme.colors.white};
  border: 2px solid ${props => props.theme.colors.white};
  border-radius: 14px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 1.056rem;
  line-height: 1.25rem;
  margin: 20px 0;
  @media (min-width: 1024px) {
    margin: 40px 0;
  }
  padding: 2.35px 22px;
  ${props => props.isAction && `
    color: ${props.theme.colors.black};
    background-color: ${props.theme.colors.white};
    border-color: ${props.theme.colors.black};
  `}
`;

export const Button = styled.button`
  color: ${props => props.theme.colors.white};
  border: 2px solid ${props => props.theme.colors.white};
  border-radius: 14px;
  padding: 3px 14px;
  cursor: pointer;
`;

/*
====================
Accueil - Sections
====================
white = Mon processus
services = services
Blogue - blogue

====================
A propos  - Sections
====================
about = topSection About

blog + blogSection

*/
export const Section = styled.section`
  & div.page-content {
    margin: 20px auto;
    @media (min-width: 1024px) {
      margin: 40px auto;
    }
    & a {
      text-decoration: underline;
      color: ${props => props.theme.colors.black};
    }
    & blockquote {
      padding: 10px;
      margin: 20px;
      margin-left: 35px;
      &>p{
        margin: 0;
        padding-left: 5px;
        border-left: 1px solid black;
      }
    }
  }
  padding: 105px 30px;
  ${props => (props.variant === 'white' && `
    background: ${props => props.theme.colors.white};
    position: relative;
    padding: 15px 30px 0 30px;
    @media (min-width: 1024px) {
      padding: 4.0625rem 20px 4.0625rem 20px;
    }
    & h3 {
      font-weight: 600;
      font-size: 32px;
      line-height: 42px;
      text-align: center;
      &.left{
        text-align: left;
      }
      color: #000000;
      @media (max-width: 1024px) {
        font-size: 30px;
        line-height: 70px;
      }
    }
    @media (min-width: 1024px) {
      & > h2{
        margin: 0 5em 1ex;
      }
      &>div.content{
        margin 0 40px;
      }
    }
    a.white{
      color: black;
      border: 2px solid #000;
      max-width: 70%;
      text-align: center;
      margin: 20px auto;
      @media (min-width: 1024px) {
        margin: 20px 52px;
      }
    }
  `)};

  ${props => (props.variant === 'services' && `
    display:flex;
    flex-direction:row;
    align-items: baseline;
    padding: 7.3125rem 2.625rem 7.3125rem 8.125rem;
    color: ${props.theme.colors.white};
    background: url(/img/S.svg) no-repeat left top fixed ${props.theme.colors.red};
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    @media (max-width: 1024px) {
      display:flex;
      flex-direction: column;
      padding:1.25rem 2.5rem 1.9rem 2.8125rem;
    }
  `)};
  ${props => (props.variant === 'blue' && `
    padding-top: 4.375rem;
    background-color: ${props.theme.colors.blue};
  `)};

  ${props => (props.variant === 'blogue' && `
    color: ${props.theme.colors.black};
    padding: 4.375rem 13.75rem 7.3125rem 8.125rem;
    background: url(/img/B_m.svg) no-repeat right bottom fixed ${props.theme.colors.violet};
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    @media (min-width: 1024px){
      background-image: url(/img/B.svg);
    }
    & > .content {
      display: flex;
      flex-direction: row;
      align-items: center;
      @media (max-width: 1024px){
        display:flex;
        flex-direction: column-reverse;
      }
      & div.image{
        min-width: 220px;
        @media (max-width: 1024px){
          width: 220px;
        }
        height: 220px;
      }
    }
    & > div > div >.blogue_title {
      font-style: normal;
      font-weight: 700;
      font-size: 50px;
      line-height: 70px;
      @media (max-width: 1024px){
        font-size: 1.875rem;
        line-height: 4.375rem;
        margin-top:4.625rem;
      }
    }
    & > div > div > p {
      margin-bottom: 2.6875rem;
      margin-right: 7.8125rem;
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 22px;
      text-align: justify;
      @media (max-width: 1024px){
        margin-right: 0;
        font-size: 1rem;
        line-height: 1.375rem;
      }
    }
    & > div > div >a {
      color: ${props.theme.colors.black};
      border-color: ${props.theme.colors.black};
    }
    @media (max-width: 1024px){
      padding:4.25rem 1.5rem 3.9rem 1.5rem;
      }
  `)};
  ${props => (props.variant === 'blogue2' && `
    color: ${props.theme.colors.black};
    background: url(/img/B.svg) no-repeat right bottom fixed ${props.theme.colors.violet};
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: inherit;
  `)};
  ${props => (props.variant === 'about' && `
    display: flex;
    flex-direction: column-reverse;
    color: ${props.theme.colors.white};
    background: url(/img/P_m.svg) no-repeat right bottom fixed ${props.theme.colors.red};
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    justify-content: flex-start;
    align-items: center;
    @media (min-width: 1024px){
      padding: 4.375rem 2.5rem 6.25rem 2.5rem;
      background-image: url(/img/P.svg);
      flex-direction: row;
      align-items: center;
    }
    & > .text {
      width: 45%;
      padding-top: 90px;
      @media (max-width: 1024px) {
        width: auto;
        padding-top: 72px;
        margin: auto;
      }
      & p a{
        text-decoration: underline;
      }
    }
    @media (min-width: 1024px) {
      & div.image-top{
        width: 450px;
        height: 450px;
      }
    }

    & > div > h2 {
      margin-bottom: 40px;
      font-style: normal;
      font-weight: 600;
      font-size: 5.625rem;
      line-height: 6.25rem;
      @media (max-width: 1024px) {
        margin-bottom: 24px;
        font-size: 3.125rem;
        line-height: 3.75rem;
      }
    }

    & > div > h3 {
      width: 90%;
      margin-bottom: 40px;
      font-style: normal;
      font-weight: 600;
      font-size: 2rem;
      line-height: 2.625rem;
      @media (max-width: 1024px) {
        width: 100%;
        margin-bottom: 24px;
        font-size: 1.375rem;
        line-height: 2rem;
      }
    }
    & > div > div > p {
      margin-bottom: 30px;
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.375rem;
      @media (max-width: 1024px) {
        margin-bottom: 20px;
      }
    }

    & .image {
      @media (max-width: 1024px){
        text-align: center;
        }
      }
    }
  `)};


  ${props => (props.variant === 'blog' && `
    padding: 4.375rem 13.75rem 7.3125rem 8.125rem;
    color: ${props.theme.colors.black};
    background: url(/img/B_m.svg) no-repeat left top fixed ${props.theme.colors.violet};
    @media (min-width: 1024px) {
      background-image: url(/img/B.svg);
    }
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    & > .content {
      display: flex;
      flex-direction: row;
      @media (max-width: 1024px){
        display:flex;
        flex-direction: column-reverse;
      }
    }
    & > div > div >.blogue_title {
      font-style: normal;
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.625rem;
      @media (max-width: 1024px){
        font-size: 1.375rem;
        line-height: 2rem;
        margin-top:2.625rem;
        margin-bottom:1.625rem;
      }
    }
    & > div > div > p {
      margin-bottom: 2.6875rem;
      margin-right: 7.8125rem;
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 22px;
      text-align: justify;
      @media (max-width: 1024px){
        margin-right: 0;
        font-size: 1rem;
        line-height: 1.375rem;
      }
    }
    & > div > div >a {
      color: ${props.theme.colors.black};
      border-color: ${props.theme.colors.black};
    }
    @media (max-width: 1024px){
      padding:4.25rem 1.5rem 3.9rem 1.5rem;
      }

  `)};
  ${props => (props.variant === 'services2' && `
    color: ${props.theme.colors.white};
    padding-top: 5.375rem;
    // padding: 5.375rem 6.3125rem 4.125rem 2.8125rem;
    background: url(/img/S2_m.svg) no-repeat right top fixed ${props.theme.colors.orange};
    @media (min-width: 1024px){
      background-image: url(/img/S2.svg);
    }
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-direction: row;

    & > .text {
      width: 60%;
      margin-right:85px;
      padding-top:100px;

      

      & > h3 {
          font-weight: 600;
          font-size: 32px;
          line-height: 42px;
          &:nth-child(4) {
            margin-top: 40px;
          }
        }

      & > h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 90px;
        line-height: 100px;
        }

      & > p {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
      }

      & > div {
        padding-top: 40px;
        padding-bottom: 27px;

        & > h3 {
          margin-bottom: 24px;
          font-weight: 600;
          font-size: 32px;
          line-height: 42px;
        }

        & > p {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }

    & > .image {
      width:30%;
      & > div.gatsby-image-wrapper{
        border-radius: 50%;
        -webkit-mask-image: -webkit-radial-gradient(white, black);
      }
    }

    @media (max-width: 1024px){
      display: flex;
      flex-direction: column-reverse;

      & > .text {
        width: 100%;
        & > h2 {
          font-size: 50px;
          line-height: 60px;
        }
      }

      & > .image {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  `)};
  ${props => (props.variant === 'contact' && `
    color: ${props.theme.colors.white};
    padding: 4.375rem 30px 0 30px;
    background: ${props.theme.colors.blue};
    & p {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
    }
  `)};
  ${props => (props.variant === 'blogue-top' && `
    color: ${props.theme.colors.white};

    background: ${props.theme.colors.violet};


    display: flex;
    flex-direction: column-reverse;
    @media (min-width: 1024px) {
      flex-direction: row;
      & > .image {
        width: 35%;
      }
    }
    & > .text {
      @media (min-width: 1024px) {
        width: 60%;
        margin-right:35px;
      }
      padding-top:10px;
      & > h2 {
        font-weight: 700;
        font-size: 50px;
        line-height: 60px;
        margin-bottom: 40px;
        @media (min-width: 1024px) {
          font-weight: 600;
          font-size: 90px;
          line-height: 100px;
        }
      }
      & > div > p {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
      }
    }
  `)};
  ${props => (props.variant === 'blog-post' && `
    color: ${props.theme.colors.black};
    padding-top: 4.375rem;
    background: url(/img/BSingle_m.svg) no-repeat right bottom ${props.theme.colors.violet};
    @media (min-width: 1024px) {
      background-image: url(/img/BSingle.svg);
    }
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    & > h3{
      text-align: center;
      margin: 40px auto;
      font-weight: 600;
      font-size: 32px;
      line-height: 42px;
    }
    & .text {
      padding: 40px 0;
      @media (min-width: 1024px) {
        max-width: 57%;
        padding: 40px 40px;
      }
    }
  `)};
`;

export const TopTitle = styled.h2`
width: 688px;
margin-top: 130px;
margin-bottom: 2.5rem;

text-align:left;
font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 5.625rem;
line-height: 6.25rem;



@media (max-width: 1024px) {
  max-height: 180px;
  width: 100%;
  margin-top: 130px;
  font-weight: 700;
  font-size: 3.125rem;
  line-height: 3.75rem;
  ${props => (props.variant === 'accueil' && `
    text-align:center;
    margin-bottom:26px;
  `)};
    ${props => (props.variant === 'others' && `
    text-align:left;
  `)};
}
`;

export const TopSubtitle = styled.h3`

`;

export const SectionTopTitle = styled.h2`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  word-wrap: break-word;
  @media (min-width: 1024px) {
    margin: 0 40px;
    font-weight: 600;
    font-size: 70px;
    line-height: 100px;
  }
`;

//Margin-top is nav heigth
export const TopContainer = styled.div`
  margin-top: 60px; 
  display: flex;
  flex-direction: column;
  & > h2 {
    text-align:center;
    margin-left: -15px;
  }
  @media (min-width: 1024px) {
    & > div, & > h2 {
      max-width: 45%;
      margin-left: auto;
    }
    justify-content: space-around;
    flex-direction: row;
  }
`;