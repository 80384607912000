import * as React from "react";
import { getImage, GatsbyImage, withArtDirection } from "gatsby-plugin-image";
import Content from "../../Content";
import { Section, SectionTopTitle } from "../../styles";
import OtherPosts from "./OtherPosts";
import useWindowSize from "../../../hooks/useWindowSize";
import { PodcastContainer, SinglePostTopContainer, YoutubeContainer } from "./styles";

export const BlogPostComponent = ({
  body,
  contentComponent,
  // description,
  image,
  smallImage,
  podcastId,
  youtubeId,
  title,
  title2,
  otherPosts,
}) => {
  const { width } = useWindowSize();
  const img = withArtDirection(getImage(image.image), [
    {
      media: "(max-width: 1024px)",
      image: getImage(smallImage.image),
    },
  ]) || image.image;
  const PageContent = contentComponent || Content;
  return (
    <Section variant="blog-post">
      <SinglePostTopContainer>
        <div className="text">
        <SectionTopTitle>
          {title}
        </SectionTopTitle>
        {podcastId && (
          <PodcastContainer>
            <iframe 
            title={title}
            frameBorder="0" 
            loading="lazy" 
            id="ausha-2aEr" 
            style={{border: 'none', width:'100%', height: width < 540 ? '494px' : '215px'}}
            src={`https://player.ausha.co/index.html?podcastId=${podcastId}&playlist=false&color=%23032CAE&v=3&playerId=ausha-2aEr`} />
            <script src="https://player.ausha.co/ausha-player.js" />
          </PodcastContainer>
        )}
        {width < 1024 && (
          <div className="image">
            {youtubeId ? (
              <YoutubeContainer>
                <iframe width="516" height="300" src={`https://www.youtube.com/embed/${youtubeId}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </YoutubeContainer>
            ) : (
              <GatsbyImage image={img} alt={image.alt} title={image.alt} />
            )}
          </div>
        )} 
          <h3>
            {title2}
          </h3>
          <PageContent className="page-content" content={body} />
        </div>
        {width >= 1024 && (
          <div className="image">
            {youtubeId ? (
              <iframe width="516" height="300" src={`https://www.youtube.com/embed/${youtubeId}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            ) : (
              <GatsbyImage image={img} alt={image.alt} title={image.alt} />
            )}
          </div>
        )}
      </SinglePostTopContainer>
      {otherPosts?.length > 0 && (
        <>
        <h3>
          Lire d'autres articles
        </h3>
        <OtherPosts posts={otherPosts} />
        </>
      )}
    </Section>
  );
}